'use strict';


angular.module('kljDigitalLibraryApp')

.controller('AccountCtrl', ["$rootScope", "$scope", "$state", "$timeout", "User", "AccountDetailsService", "appConfig", "commonVariables", "ngToast", "MainService", function($rootScope, $scope, $state, $timeout,
    User, AccountDetailsService, appConfig, commonVariables, ngToast, MainService) {

    var key;
    $scope.addressFull = [];


    commonVariables.isHome = false;
    var init = function() {
        var mapping = {
            'change-password': 'Change Password',
            'setting': "Setting",
            'profile': 'Profile'
        }
        $scope.user = $rootScope.currentUser ? $rootScope.currentUser : {};
        $rootScope.title = mapping[$state.params.operation];
        if ($state.params) {
            $scope.operation = $state.params.operation;
        }
        getUserDetails();
        getStateCityInfo();
    }

    var getStateCityInfo = function() {
        return MainService.getList()
            .then(function(response) {
                $scope.citiesList = response.cities;
                $scope.statesList = response.states;
            })
            .catch(function(error) {
                console.log("error-->", error);
            });
    }


    var getUserDetails = function() {
        var currentUser = User.get().$promise;
        currentUser
            .then(function(user) {
                setUserDetails(user);
            })
            .catch(function(error) {

            })
    }

    var setUserDetails = function(user) {
        // console.log(user.addresses[0])
        $scope.user = user;
        $scope.user.dob = new Date($scope.user.dob);
        $scope.id = user._id;

        // var addressObj = {
        //     address: $scope.user.addresses[0].address,
        //     city: $scope.user.addresses[0].city,
        //     landmark: $scope.user.addresses[0].landmark,
        //     pincode: $scope.user.addresses[0].pincode,
        //     state: $scope.user.addresses[0].state
        // }

        // for (key in addressObj) {
        //     $scope.addressFull.push(addressObj[key]);
        // }

        $rootScope.title = user.first_name + " " + user.last_name + " | Profile";
    }


    //Cancel profile details updation
    $scope.cancel = function() {
        // getUserDetails();
        // ngToast.create("Profile update is aborted.", "success");
        $state.go("index.home");
    }

    $scope.getNumber = function(num) {
        return new Array(num);
    }

    angular.element(document).ready(function() {

        $('.wrapper-inner').css('height', $(window).height() - appConfig.HEADER_HEIGHT_1024 + "px");

    });


    //Update notification settings
    $scope.updateNotification = function() {
        var body = {
            notification_settings: $scope.user.notification_settings
        };
        AccountDetailsService.update(body)

        .then(function(data) {
            ngToast.create('Notification settings updated successfully');
        })

        .catch(function(error) {
            ngToast.create('Notification settings updation failed');
        });

    }


    //Update user profile
    $scope.updateProfile = function(valid, user) {
        $scope.submit = true;
        if (valid) {
            AccountDetailsService.update(user)
                .then(function(data) {
                    $scope.submit = false;
                    var name = user.first_name + " " + user.last_name;
                    //Update Username
                    $scope.$emit("updateUsername", name);
                    ngToast.create('Profile details updated successfully');

                })
                .catch(function(error) {
                    ngToast.create('Profile details updation failed');
                });
        }
    }

    //Event Emiters: communication between controllers
    $scope.$emit('showSearchBox', true);

    init();

}]);